<template>
  <div class="modal-parent">
    <div class="gala">
      <main class="gala-container">
        <!-- BANNER -->
        <div class="gala-banner">
          <img src="../../../assets/gala/images/banner.png" alt="Banner" class="img-fluid mx-auto"/>
          <img src="../../../assets/gala/images/logo.png" alt="Banner Logo" class="gala-banner-logo">
          <div class="gala-banner-text">
            <p class="text-white m-0">Vota por el mejor vestido</p>
            <p class="text-white m-0">Participa por $500.000</p>
            <router-link to="/gala">
              <a class="btn btn-gradiente-cyan w-100" href="#" @click="handleLogout" role="button" data-bs-toggle="modal" data-bs-target="#votaGH1">Volver</a>
            </router-link>
            <p class="text-white m-0">Seleccione Usuario a modificar</p>
          </div>
        </div>
        <!-- GRILLA -->
        <div class="gala-grid">
          <div class="gala-grid-item" v-for="(item, i) in jsonData" :key="i">
            <div class="gala-grid-item-alias">
              <h2>{{ item.alias }}</h2>
              <div class="gala-grid-item-button">
                <button @click="sendDataForModal(item.nombre)">Editar</button>
              </div>
              <p>{{ item.nombre }}</p>
            </div>
          </div>
        </div>
      </main>
      <footer>
        <a href="https://concursos.mega.cl/home/" target="_blank">Base del concurso</a>
        <div>
          <p>Desarrollado por</p>
          <a href="mailto:contacto@celcom.cl" target="_blank">
            <img src="../../../assets/galeria/logo_celcom_blanco.svg" alt="Logo Celcomlatam blanco"/>
          </a>
        </div>
      </footer>
      <div class="row justify-content-center">
        <div class="col-xl-8 text-center p-0">
          <div v-if="jsonData && !hayParticipantesActivos" class="got-votacion justify-content-center text-white text-uppercase">
            <h1 class="py-5 display-1">¡Votaciones Cerradas!</h1>
          </div>
          <div v-else class="gh-votacion justify-content-center text-white text-uppercase"/>
        </div>
      </div>
    </div>
      <ModalVotacionGala 
        v-if="modaIsActive"
        :dataForVotes="dataForVotes"
        :modaIsActive="modaIsActive"
        @changeModalIsActive="changeModalIsActive"
      />
      <LoaderComponent v-if="loaderIsActive"/>
    </div>
</template>

<script>
import ModalVotacionGala from "./ModalAdminGala.vue";
import axios from "axios";
import { logout } from '@/auth.js';
import LoaderComponent from "../../LoaderComponent/LoaderComponent.vue";
export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: 'AdminGala',
  components: {
    ModalVotacionGala,
    LoaderComponent
  },
  data() {
    return {
      activeSection: null, // Para controlar qué sección está abierta
      cantidadActivos: null,

      jsonData: null,
      jsonDataAnterior: null,
      jsonLoaded: false,
      dataForVotes: {},
      modaIsActive: false,
      loaderIsActive: true,
    };
  },
  computed: {
    agrupadosPorCategoria() {
      // Reducimos el array en un objeto categorizado
      const categoriasAgrupadas = this.jsonDataAnterior.reduce((acc, participante) => {
        const categoria = participante.category || 'Sin categoría';
        if (!acc[categoria]) {
          acc[categoria] = [];
        }
        acc[categoria].push(participante);
        return acc;
      }, {});

      // Ordenamos las claves en orden inverso (descendente)
      const categoriasOrdenadas = Object.keys(categoriasAgrupadas)
          .sort((a, b) => {
            const numA = parseInt(a.match(/\d+/)); // Extrae el número de la semana
            const numB = parseInt(b.match(/\d+/));
            return numB - numA; // Ordena de mayor a menor
          })
          .reduce((acc, key) => {
            acc[key] = categoriasAgrupadas[key];
            return acc;
          }, {});

      return categoriasOrdenadas;
    },
    hayParticipantesActivos() {
      return this.jsonData.some(item => item.active);
    },
    claseFondo() {
      if (this.cantidadActivos <= 4) {
        return 'bg-dark got-landing';
      } else {
        return 'bg-dark got-landing';
      }
    },
    claseContenedor() {
      if (this.cantidadActivos === 2) {
        return 'got-votacion-contenedores grid-t-c-2';
      } else {
        return 'got-votacion-contenedores grid-t-c-2';
      }
    },
  },
  methods: {
    toggleSection(categoria) {
      this.activeSection = this.activeSection === categoria ? null : categoria;
    },
    cargarData() {
      axios.get("https://votaciongh.uc.r.appspot.com/participantes_general/lp/3")
      //axios.get("http://192.168.1.7:3113/participantes1")
          .then(response => {
            console.log('Se han cargado de forma correcta los Datos');
            this.jsonData = response.data
              .filter(item => item.active && item.category === null)
              .sort((a, b) => {
                const numA = parseInt(a.nombre.replace(/\D/g, ""), 10) || 0;
                const numB = parseInt(b.nombre.replace(/\D/g, ""), 10) || 0;
                return numA - numB;
            });
            this.jsonDataAnterior = response.data.filter(item => item.active && item.category !== null);
            this.cantidadActivos = this.jsonData.filter(item => item.active).length;
          })
          .finally(() => {
            this.loaderIsActive = false;
          })
          .catch(error => {
            console.error(error);
          });
      console.log(this.jsonData);
    },
    changeModalIsActive(value) {
      this.modaIsActive = value;
    },
    sendDataForModal(name){
      this.dataForVotes = {};
      this.modaIsActive = true;
      setTimeout(() => {
        const selectedItem = this.jsonData.find(item => item.nombre === name);
        this.dataForVotes = selectedItem;
      }, 10);
    },
     importarJson() {
      window.location.reload();
      // Hacer algo con this.jsonData
    },
    handleLogout() {
      logout();
      // Realiza cualquier acción adicional después del cierre de sesión
    },
  },
  mounted() {
    this.cargarData();
  },
}
</script>
<style scoped lang="scss">
@font-face {
  font-family: 'avenir-regular';
  src: url('../../../assets/gala/fonts/AVENIR-REGULAR.OTF');
}

@font-face {
  font-family: 'avenir-bold';
  src: url('../../../assets/gala/fonts/AVENIR-BOLD.TTF');
}

.gala{
  background-image: url('../../../assets/gala/images/background.png');
  background-position: center;
  background-size: cover;
  font-family: 'avenir-regular';
  min-height: 100vh;
  padding-bottom: 24px;
  position: relative;

  p{
    margin: 0;
  }

  a{
    text-decoration: none;
    color: inherit;
  }

  &-banner{
    position: relative;

    &-logo{
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 250px;
    }

    &-text{
      background-color: #000;
      color: #FFF;
      text-align: center;
      padding: 8px 16px;
      font-size: 14px; 
    }
  }

  footer{
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    background-color: #000;
    color: #FFF;
    padding: 8px 0;
    font-size: 14px;
    text-align: center;

    a{
      text-decoration: underline;
    }

    div{
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 4px;

      a{
        text-decoration: none;
        
        img{
          margin-top: -4px;
          width: 70px;
        }
      } 
    }
  }
}

.gala-container {
  width: 100%;
  margin: 0 auto;
  
  // Tamaño por defecto (Mobile)
  max-width: 480px;

  // Tablet
  @media (min-width: 481px) {
    max-width: 640px;
  }

  // Desktop
  @media (min-width: 1024px) {
    max-width: 960px;
  }
}

.gala-grid {
  padding: 16px;
  display: grid;
  gap: 16px; 

  grid-template-columns: repeat(3, 1fr);

  @media (min-width: 481px) {
    grid-template-columns: repeat(4, 1fr);
  }

  @media (min-width: 1024px) {
    grid-template-columns: repeat(5, 1fr);
  }

  &-item{
    position: relative;
    padding: 8px;
    background-image: linear-gradient(45deg, #2c2b5e, #111138);
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.3);
    border-radius: 6px;
    overflow: hidden;

    
    p{
      font-size: 14px;
      color: #FFF;
    }

    &-number{
      position: absolute;
      top: 60%;
      right: -20px;
      transform: translateY(-50%);
      width: 80px;
      height: 80px;
      border: 10px solid rgba(90, 47, 126, 0.1);
      border-radius: 50%;
      z-index: 0;  

      p{
        font-family: 'avenir-bold';
      color: rgba(90, 47, 126, 0.1);
      position: absolute;
      top: 45%;
      left: 50%;
      transform: translate(-50%, -50%);
      font-size: 24px;
      }
    }

    &-alias{
      text-align: center;

      h2{
        font-size: 18px;
        font-family: 'avenir-bold';
        color: #FFF;
      }
      
      p{
        margin-bottom: 4px;
        font-size: 12px;
        color: #FFF;
      }
    }

    &-button{
      position: relative;
      margin-top: 4px;
      text-align: center;

      button{
        outline: none;
        border: none;
        border-radius: 12px;
        background: linear-gradient(45deg, #ff9800, #ff5722); /* Tonos cálidos del logo */
        font-size: 14px;
        font-weight: bold;
        color: #FFF;
        padding: 6px 16px;
        letter-spacing: 0.5px;
        width: 100%;
        cursor: pointer;
        transition: all 0.3s ease-in-out;
        box-shadow: 0 4px 12px rgba(255, 152, 0, 0.4); /* Resaltado */
        margin-bottom: 8px;
      }
    }
  }
}

.modal-parent{
  overflow: hidden;
}
</style>
