<template>
  <div :class="clase">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-md-6 text-center p-0">
          <div v-if="!gala" class="tb-banner">
            <img srcset="" :src="urlImg" alt=" " class="d-none d-md-block img-fluid mx-auto" />
             <img srcset="" :src="urlImgMovil" alt=" " class="d-md-none img-fluid mx-auto" />
          </div>
          <div v-else class="fes-lc-banner gala-banner">
            <img
                srcset=""
                src="@/assets/gala/images/banner.png"
                alt=" "
                class="img-fluid mx-auto"
            />
            <img src="@/assets/gala/images/logo.png" alt="" class="gala-banner-logo">
          </div>
          <div class="p-4">
          <Trivia
              v-if="triviaStatus"
              :c13="c13"
              :got="got"
              :bunkers="bunkers"
              :mne="mne"
              :clasetext="clasetext"
              :festivallascondes="festivallascondes"
              :gala="gala"
              @changeValue="updateParentValue"
          />
          <Mensaje v-if="modal" :clasetext="clasetext" @changeValue="updateParentValue"/>
            <div v-else-if="!modal && !triviaStatus">
              <h1 :class="{ 'py-5': true, [clasetextGracias]: true }">¡Gracias!</h1>
              <h2 :class="clasetextGracias">Participación realizada exitosamente, acabas de sumar {{ (opciones ? opciones : 'más') }} opciones de ganar.</h2>
              <h2 :class="clasetextGracias" style=" font-size: 18px">Pago procesado con {{ status }} para {{ opccion }} con nc {{ nc }}.</h2>
            </div>
          </div>
          <div class="bg-black text-white fixed-bottom">
            <div class="col text-center"><span>Desarrollado por </span><a href="https://www.celcomlatam.com/"><img srcset="" :src="require('@/assets/galeria/logo_celcom_blanco.svg')" alt="Logo Celcomlatam blanco" class="logo-celcom img-fluid"/></a></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import Trivia from "@/components/Trivia.vue";
import Mensaje from "@/components/Mensaje.vue";

export default {
  name: 'HomeView',
  components: {
    Trivia,
    Mensaje,
  },
  data() {
    return {
      clase: null,
      clasetext: null,
      clasetextGracias: null,
      urlImg: null,
      urlImgMovil: null,
      c13: false,
      got: false,
      bunkers: false,
      festivallascondes: false,
      mne: false,
      triviaStatus: false,
      status: null,
      opccion: null,
      nc: null,
      gala: null,
      modal: false,
      opciones: null,
    };
  },
  methods: {
    closeModal() {
      this.showModal = false;
    },
    updateParentValue(data) {
      this.triviaStatus = data.triviaStatus;
      this.modal = data.modal;
      if (this.modal) {
        this.time();
      }
    },
    time() {
      setTimeout(() => {
        // Cambiar el valor de la variable después de 3 segundos (3000 ms)
        this.modal = false;
        this.triviaStatus = true;
      }, 3000); // Cambiar el tiempo (en milisegundos) según tus necesidades
    },
    setLandingPageData(opccion) {
      // Configura valores dinámicos según `opccion`
      switch (opccion) {
        case 'concurso 13':
          this.clase = 'lp-c13-generico vh-100';
          this.clasetext = 'text-black-50 text-uppercase h5';
          this.clasetextGracias = 'text-black-50';
          this.c13 = true;
          this.urlImg = require('@/assets/galeria/banner_c13_desktop.png');
          this.urlImgMovil = require('@/assets/galeria/banner_c13_mobile.png');
          break;
        case 'got':
          this.clase = 'bg-dark got-landing bg-gradiente-azul-oscuro-a';
          this.clasetext = 'text-white text-uppercase h5';
          this.clasetextGracias = 'text-white';
          this.got = true;
          this.urlImg = require('@/assets/galeria/lnd_got_header_hq.png');
          this.urlImgMovil = require('@/assets/galeria/lnd_got_header_hq.png');
          break;
        case 'bunkers':
          this.clase = 'bg-dark got-landing bg-gradiente-azul-oscuro-a';
          this.clasetext = 'text-white text-uppercase h5';
          this.clasetextGracias = 'text-white';
          this.bunkers = true;
          this.urlImg = require('@/assets/galeria/BANNER.png');
          this.urlImgMovil = require('@/assets/galeria/BANNER_mobile.png');
          break;
        case 'mne':
          this.clase = 'bg-dark got-landing bg-gradiente-azul-oscuro-a';
          this.clasetext = 'text-white text-uppercase h5';
          this.clasetextGracias = 'text-white';
          this.mne = true;
          this.urlImg = require('@/assets/galeria/LOGO_FONDO_LUCES.jpg');
          this.urlImgMovil = require('@/assets/galeria/LOGO_FONDO_LUCES.jpg');
          break;
        case 'festivallascondes':
          this.clase = 'fes-lc-landing bg-gradiente-azul-oscuro-a';
          this.clasetext = 'text-white text-uppercase h5';
          this.clasetextGracias = 'text-white';
          this.festivallascondes = true;
          this.urlImg = require('@/assets/galeria/banner_festival_lc_2.jpg');
          this.urlImgMovil = require('@/assets/galeria/banner_festival_lc_2.jpg');
          break;
        case 'gala':
          this.clase = 'gala vh-100';
          this.clasetext = 'text-white text-uppercase h5';
          this.clasetextGracias = 'text-white';
          this.gala = true;
          this.urlImg = require('@/assets/gala/images/banner.png');
          this.urlImgMovil = require('@/assets/gala/images/banner.png');
        break;
        default:
          this.clase = 'bg-tierra-brava vh-100';
          this.clasetext = 'text-white text-uppercase h5';
          this.clasetextGracias = 'text-white';
          this.urlImg = require('@/assets/galeria/img_tb_main_desktop.jpg');
          this.urlImgMovil = require('@/assets/galeria/img_tb_main.jpg');
          break;
      }
    },
  },
  mounted() {
    // Obtén el valor inicial desde la URL
    this.opciones = this.$route.query.opciones;
    let opccion = this.$route.query.opccion;

    // Cambia el valor de `opccion` si es necesario
    if (opccion === 'bunkers') {
      opccion = 'festivallascondes';
      this.opccion = opccion;
      // Actualiza la URL
      this.$router.replace({
        query: {
          ...this.$route.query, // Conserva otros parámetros
          opccion, // Cambia `opccion` en la URL
        },
      });
    } else {
      this.opccion = opccion;
    }

    // Si hay éxito y `opccion` está definida
    if (this.$route.query.status === 'success' && this.opccion) {
      this.status = this.$route.query.status;
      this.nc = this.$route.query.nc;
      this.triviaStatus = true;

      // Llama a un método centralizado para manejar las clases y estilos
      this.setLandingPageData(this.opccion.toLowerCase());
    } else {
      // Redirigir a la raíz si no hay parámetros válidos
      this.$router.push('/');
    }
  },
}

</script>
<style scoped lang="scss">
.gala{
  background-image: url('@/assets/gala/images/background.png');
  background-position: center;
  background-size: cover;

  &-banner{
    position: relative;

    &-logo{
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 250px;
    }
  }
}
</style>