<template>
  <div class="modal-votacion">
    <div class="modal-container">
      <div class="modal-header">
        <button type="button" @click="closeModal" class="btn-close" aria-label="Cerrar">✖</button>
      </div>
      <div class="modal-body">
        <h2 class="modal-title">{{ dataForVotes.nombre }}</h2>
        <form>
          <div class="form-group">
            <label for="inputNombre" class="label-nombre">Alias</label>
            <input 
              type="text" 
              id="inputNombre" 
              v-model="editalias" 
              class="input-nombre" 
              :style="{ width: dynamicInputWidth }"
            >
          </div>
          <div class="modal-footer">
            <button class="btn-gradiente-morado" @click="modificar">Editar</button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
export default {
  props: {
    dataForVotes: {
      type: Object,
      required: true,
      default: () => {},
    },
    modalIsActive: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      localDataForVotes: { ...this.dataForVotes }, // Se crea una copia para modificar
      editalias: "",
    };
  },
  watch: {
    dataForVotes: {
      handler(newValue) {
        this.localDataForVotes = { ...newValue }; // Se copia el objeto cuando cambia
        this.editalias = newValue.alias || "";
      },
      deep: true,
      immediate: true,
    }
  },
  computed: {
    dynamicInputWidth() {
      const baseWidth = 120; // Tamaño fijo mínimo
      const charWidth = 10;  // Espacio por caracter
      const maxWidth = 300;  // Tamaño máximo permitido

      let newWidth = this.editalias.length * charWidth;
      return newWidth > baseWidth ? `${Math.min(newWidth, maxWidth)}px` : `${baseWidth}px`;
    },
  },
  methods: {
    closeModal() {
      this.$emit('changeModalIsActive', false);
    },
    ejecutarImportarJson() {
      this.$emit('importar-json');
    },
    modificar() {
      // Usamos la copia local en lugar del `prop` original
      this.localDataForVotes.alias = this.editalias;

      const token = localStorage.getItem('token');
      axios.post("https://votaciongh.uc.r.appspot.com/participante_general", this.localDataForVotes, {
        headers: {
          'Authorization': token,
          'Content-Type': 'application/json'
        },
      })
      .then(response => {
        console.log('Alias modificado correctamente: ' + response.data.alias);
        this.ejecutarImportarJson();
        this.closeModal();
      })
      .catch(error => {
        console.error(error);
      });
    }
  },
};
</script>

<style scoped>
/* Fondo semi-transparente */
.modal-votacion {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

/* Contenedor del modal */
.modal-container {
  background: #1f1f1f;
  padding: 20px;
  border-radius: 12px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.3);
  width: 400px;
  text-align: center;
  color: white;
}

/* Cabecera del modal */
.modal-header {
  display: flex;
  justify-content: flex-end;
}

/* Botón de cerrar */
.btn-close {
  background: none;
  border: none;
  font-size: 18px;
  cursor: pointer;
  color: white;
  transition: 0.3s ease;
}

.btn-close:hover {
  color: #ff4a4a;
  transform: scale(1.2);
}

/* Título */
.modal-title {
  font-size: 22px;
  margin-bottom: 15px;
  font-weight: bold;
}

/* Campo de entrada */
.input-nombre {
  display: block;
  margin: 10px auto;
  padding: 8px;
  border-radius: 8px;
  border: 1px solid #666;
  background: #2b2b2b;
  color: white;
  text-align: center;
  font-size: 16px;
  width: 120px;
  transition: width 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
}

.input-nombre:focus {
  outline: none;
  box-shadow: 0px 0px 10px rgba(255, 255, 255, 0.3);
}

/* Botón */
.btn-gradiente-morado {
  display: inline-block;
  width: 100%;
  background: linear-gradient(45deg, #6a11cb, #2575fc);
  color: white;
  padding: 10px;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  font-size: 16px;
  font-weight: bold;
  transition: 0.3s ease;
}

.btn-gradiente-morado:hover {
  background: linear-gradient(45deg, #2575fc, #6a11cb);
  transform: scale(1.05);
}
</style>
